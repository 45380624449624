import { IconName } from "@components/library/Icon/Icon";
import { request_for_expert_path, submit_proposal_path } from "@routes/routes";
import {
  CompanyPartial,
  RequestForCard,
  ReviewTeamMember,
  ReviewerDashboardRequest,
  RfpPageRequest,
  RolePartial,
  ScientistCurrentUser,
  SponsorDropdownRequest,
  SponsorProfilePartial,
} from "@tsTypes/index";
import {
  LaunchableRequestStatus,
  RequestFeedbackDeadlineStatus,
  RequestableType,
} from "@tsTypes/requests";
import { RoleName } from "@tsTypes/roles";
import { Company, OpenCall, Request, User } from "@tsTypes/schema";
import { SponsorLicenseType } from "@tsTypes/sponsorProfiles";
import { AnyUser, UserRole } from "@tsTypes/users";
import { differenceInDays, isAfter, isBefore } from "date-fns";
import { uniqBy } from "lodash";
import { REQUESTABLE_TO_REQUEST_TYPE } from "src/App";
import { SortBy } from "src/views/Marketplace/RespondToRequests";
import { RfpOpenCallProposalFormRequest } from "src/views/RfpOpenCallProposalForm/RfpOpenCallProposalForm";
import { ACCESS_CODE_PARAM } from "src/views/RFPPage/RfpPage";
import { assertAbsurd } from "./tsUtils";
import { sortByHasProfilePicture } from "./userUtils";

export const REQUESTABLE_TO_ICON_NAME: { [key in RequestableType]: IconName } = {
  [RequestableType.OPEN_CALL]: "Open Call",
  [RequestableType.RFP]: "Opportunity",
  [RequestableType.REQUEST_FOR_STARTUP]: "Accelerator",
  [RequestableType.REQUEST_FOR_EXPERT]: "Request for experts",
};

export const findRequestRoleNameOnUser = (
  user: ReviewTeamMember,
  request: Pick<Request, "id" | "requestable_type"> & { complete?: boolean }
): RoleName | null => {
  const isDraft = request.complete !== undefined && !request.complete;

  if (user.role === UserRole.SPONSOR) {
    const { license, is_admin, is_super_admin } = user.profile_info;
    const isPremium = license === SponsorLicenseType.PREMIUM;
    const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

    if (is_admin && (isPremium || isDraft || isOpenCall)) {
      if (is_super_admin) {
        return RoleName.COMPANY_SUPER_ADMIN;
      }
      return RoleName.COMPANY_ADMIN;
    }
  }

  return (
    user.roles.find(
      (role) =>
        role.resource_type === "Request" &&
        role.resource_id === request.id &&
        isDraft === (role.name === RoleName.REQUEST_DRAFT_EDITOR)
    )?.name ?? null
  );
};

export const canAccessRequest = (
  user: Pick<User, "role"> & {
    roles: RolePartial[];
    profile_info: Partial<Pick<SponsorProfilePartial, "license" | "is_admin" | "company_id">>;
  },
  request: Pick<Request, "id" | "requestable_type"> & {
    complete?: boolean;
    company: Partial<Pick<Company, "id">>;
  }
): boolean => {
  if (!user || !request) return false;

  const { license, is_admin, company_id } = user.profile_info as SponsorProfilePartial;
  const isSponsorAtCompany = user.role === UserRole.SPONSOR && request.company.id === company_id;
  const isPremium = license === SponsorLicenseType.PREMIUM;
  const isDraft = request.complete !== undefined && !request.complete;
  const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

  if (isSponsorAtCompany && is_admin && (isPremium || isDraft || isOpenCall)) {
    return true;
  }

  return Boolean(
    user.roles.some(
      (role) =>
        role.resource_type === "Request" &&
        role.resource_id === request.id &&
        (isDraft
          ? role.name === RoleName.REQUEST_DRAFT_EDITOR
          : role.name === RoleName.REQUEST_LEAD ||
            role.name === RoleName.REQUEST_COMMENTER ||
            role.name === RoleName.REQUEST_EXPERT ||
            role.name === RoleName.REQUEST_LEAD_EXPERT)
    )
  );
};

export const canAccessRequestAsLead = (
  user: Pick<User, "role"> & {
    roles: RolePartial[];
    profile_info:
      | Pick<SponsorProfilePartial, "license" | "is_admin" | "company_id">
      | Record<string, unknown>;
  },
  request: Pick<Request, "id" | "requestable_type"> & {
    complete?: boolean;
    company: Partial<Pick<Company, "id">>;
  }
): boolean => {
  const { license, is_admin, company_id } = user.profile_info as SponsorProfilePartial;

  if (
    !user ||
    !("id" in user) ||
    !request ||
    user.role !== UserRole.SPONSOR ||
    request.company.id !== company_id
  ) {
    return false;
  }

  const isPremium = license === SponsorLicenseType.PREMIUM;
  const isDraft = request.complete !== undefined && !request.complete;
  const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

  if (is_admin && (isPremium || isDraft || isOpenCall)) {
    return true;
  }

  return Boolean(
    user.roles.some(
      (role) =>
        role.resource_type === "Request" &&
        role.resource_id === request.id &&
        (isDraft
          ? role.name === RoleName.REQUEST_DRAFT_EDITOR
          : role.name === RoleName.REQUEST_LEAD)
    )
  );
};

export const isExternalLeadOnRequest = (
  currentUser: AnyUser,
  request: Pick<Request, "id" | "requestable_type"> & {
    complete?: boolean;
    company: Partial<Pick<Company, "id">>;
  }
): boolean => {
  if ("is_expert" in currentUser.profile_info) {
    return (
      findRequestRoleNameOnUser(currentUser as ScientistCurrentUser, request) ===
      RoleName.REQUEST_LEAD_EXPERT
    );
  }

  return false;
};

export const filterReviewTeamByRole = ({
  requestId,
  team,
  roleName,
}: {
  requestId: number;
  team: ReviewTeamMember[];
  roleName: RoleName;
}): ReviewTeamMember[] =>
  team.filter((member) =>
    member.roles.find(
      (role) =>
        role.resource_type === "Request" && role.resource_id === requestId && role.name === roleName
    )
  );

export const reviewTeamMembersForRfpPage = (
  request: RfpPageRequest & { company: CompanyPartial }
): ReviewTeamMember[] => {
  if (request.confidential) return [];

  const reviewTeamMembers = request.review_team_members;
  if (!reviewTeamMembers) return [];

  return sortByHasProfilePicture(
    reviewTeamMembers?.filter((reviewer) => canAccessRequestAsLead(reviewer, request))
  );
};

export const reviewTeamMembersForReviewerDashboardThumbnails = (
  request: ReviewerDashboardRequest
) => {
  const reviewTeamMembers = request.review_team_members;
  const superAdminReviewers = reviewTeamMembers.filter(
    (reviewer) => reviewer.role === UserRole.SPONSOR && reviewer.profile_info.is_super_admin
  );
  const onlyAdminReviewers = reviewTeamMembers.filter(
    (reviewer) =>
      reviewer.role === UserRole.SPONSOR &&
      reviewer.profile_info.is_admin &&
      !reviewer.profile_info.is_super_admin
  );
  const leads = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_LEAD,
    })
  );
  const commenters = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_COMMENTER,
    })
  );
  const externalLeads = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_LEAD_EXPERT,
    })
  );
  const externalCommenters = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_EXPERT,
    })
  );
  const draftEditors = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_DRAFT_EDITOR,
    })
  );

  return uniqBy(
    [
      ...(request.complete
        ? [...leads, ...commenters, ...externalLeads, ...externalCommenters]
        : draftEditors),
      ...onlyAdminReviewers,
      ...superAdminReviewers,
    ],
    (reviewer) => reviewer.id
  ).slice(0, 3);
};

export const requestPageUrlPath = (
  request?: Record<string, any>,
  hideAccessCode = false
): string => {
  switch (request?.requestable_type) {
    case RequestableType.OPEN_CALL: {
      return `/company/${request.company.identifier}`;
    }
    case RequestableType.RFP: {
      return (
        `/research/${request.requestable?.rfp_program_identifier || "program"}/${request.slug}` +
        (request.access_code && !hideAccessCode
          ? `?${ACCESS_CODE_PARAM}=${request.access_code}`
          : "")
      );
    }
    case RequestableType.REQUEST_FOR_STARTUP: {
      return `/startup_program/${request.slug}`;
    }
    case RequestableType.REQUEST_FOR_EXPERT: {
      return request_for_expert_path(request.slug);
    }
    default: {
      throw new Error(`Invalid requestable type: ${request?.requestable_type}`);
    }
  }
};

export const requestProposalUrlPath = (
  request: {
    requestable_type: RequestableType | string;
    slug: string;
    access_code?: string | null;
  },
  options?: any
): string => {
  return submit_proposal_path(
    REQUESTABLE_TO_REQUEST_TYPE[request.requestable_type],
    request.slug,
    request.access_code
      ? {
          [ACCESS_CODE_PARAM]: request.access_code,
          ...options,
        }
      : options
  );
};

export type LaunchableRequest = Pick<Request, "enabled" | "feedback_deadline"> & {
  requestable: {
    launch_date: string | null;
    deadline: string | null;
  };
};

export const getLaunchableRequestStatus = <Request extends LaunchableRequest>(
  request: Request
): LaunchableRequestStatus => {
  if (!request.requestable.launch_date && !request.requestable.deadline) {
    if (request.enabled) return LaunchableRequestStatus.ACTIVE;
    return LaunchableRequestStatus.CLOSED;
  }

  const isBeforeLaunchDate =
    Boolean(request.requestable.launch_date) &&
    isBefore(new Date(), new Date(request.requestable.launch_date ?? 0));
  const isPastDeadline =
    Boolean(request.requestable.deadline) &&
    isAfter(new Date(), new Date(request.requestable.deadline ?? 0));

  if (isBeforeLaunchDate) return LaunchableRequestStatus.COMING_SOON;
  if (request.enabled)
    if (isPastDeadline) return LaunchableRequestStatus.IN_EXTENSION;
    else return LaunchableRequestStatus.ACTIVE;
  return LaunchableRequestStatus.CLOSED;
};

export const canUseFeedbackDeadline = (request: {
  created_at?: string;
  requestable?: {
    launch_date: string | null;
    deadline: string | null;
  } | null;
}): boolean => {
  const FEEDBACK_DEADLINE_CUTOFF_DATE = new Date("8-17-2023");

  return (
    new Date(request.created_at ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE ||
    new Date(request.requestable?.launch_date ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE ||
    new Date(request.requestable?.deadline ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE
  );
};

export const getRequestFeedbackDeadlineStatus = (request: {
  feedback_deadline: string | null;
  created_at?: string;
  requestable?: {
    launch_date: string | null;
    deadline: string | null;
  } | null;
}): RequestFeedbackDeadlineStatus => {
  if (
    !canUseFeedbackDeadline(request) ||
    !request.requestable?.deadline ||
    new Date() < new Date(request.requestable?.deadline)
  ) {
    return RequestFeedbackDeadlineStatus.NOT_APPLICABLE;
  }

  if (!request.feedback_deadline) return RequestFeedbackDeadlineStatus.NEEDS_DEADLINE;

  const daysUntilDeadline = differenceInDays(new Date(request.feedback_deadline), new Date());

  if (daysUntilDeadline < 0) return RequestFeedbackDeadlineStatus.PAST_DEADLINE;
  if (daysUntilDeadline < 5) return RequestFeedbackDeadlineStatus.DEADLINE_APPROACHING;
  return RequestFeedbackDeadlineStatus.BEFORE_DEADLINE;
};

export const includesSolutionsAndTrlAndRequirements = (
  request?: SponsorDropdownRequest | RfpOpenCallProposalFormRequest
): boolean => {
  if (!request) return false;

  if (!request.requestable) return false;

  if (request.requestable_type === RequestableType.OPEN_CALL) return false;

  const requestable = request.requestable as Exclude<
    typeof request.requestable,
    Pick<OpenCall, "company_research_interest_id">
  >;

  const { launch_date } = requestable;

  if (!launch_date) return false;

  return new Date(launch_date) > new Date(Date.UTC(2024, 0, 1));
};

export const sortMarketplaceRequests = ({
  requests,
  scores,
  sort,
}: {
  requests: RequestForCard[];
  scores: Record<number, number>;
  sort: SortBy;
}): void => {
  // @ts-ignore
  requests.sort((a, b) => {
    const aStatus = getLaunchableRequestStatus(a as any);
    const bStatus = getLaunchableRequestStatus(b as any);

    // Sort elements with status higher than elements without status
    const compareStatus = (status: LaunchableRequestStatus) =>
      Number(bStatus === status) - Number(aStatus === status);

    const compareScores = scores[b.id] - scores[a.id];

    const compareLaunchDate =
      new Date(a.requestable.launch_date!).getTime() -
      new Date(b.requestable.launch_date!).getTime();

    const compareDeadline =
      new Date(b.requestable.deadline!).getTime() - new Date(a.requestable.deadline!).getTime();

    switch (sort) {
      case SortBy.RELEVANCE:
        return (
          (compareStatus(LaunchableRequestStatus.ACTIVE) ||
            compareStatus(LaunchableRequestStatus.COMING_SOON) ||
            compareStatus(LaunchableRequestStatus.IN_EXTENSION) ||
            compareStatus(LaunchableRequestStatus.CLOSED) ||
            compareScores ||
            (aStatus === LaunchableRequestStatus.COMING_SOON
              ? compareLaunchDate
              : compareDeadline)) ??
          0
        );
      case SortBy.LAUNCH_DATE:
        return -compareLaunchDate || compareScores;
      case SortBy.DEADLINE:
        return (
          (compareStatus(LaunchableRequestStatus.ACTIVE) ||
            compareStatus(LaunchableRequestStatus.COMING_SOON) ||
            compareStatus(LaunchableRequestStatus.IN_EXTENSION) ||
            compareStatus(LaunchableRequestStatus.CLOSED) ||
            (aStatus === LaunchableRequestStatus.COMING_SOON
              ? compareLaunchDate
              : compareDeadline) ||
            compareScores) ??
          0
        );
      default:
        assertAbsurd(sort);
    }
  });
};
